@media(max-width: 1000px) {
  #material-layout .material {
    flex-wrap: wrap;
  }

  #material-layout .material .info {
    display: flex;
  }

  #material-layout .material .info > div {
    margin: 5px;
  }
}

@media(min-width: 1000px) {
  #material-layout .material .info > div {
    margin-bottom: 15px;
  }
}

#material-layout .material .description {
  max-width: 800px;
  margin: 20px;
  white-space: pre-line;
  margin-bottom: 30px;
}

#material-layout .material .info {
  margin: 20px;
}