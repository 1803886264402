/*.App {
  text-align: center;
}*/

/*
 https://github.com/TeamWertarbyte/material-ui-search-bar/issues/23 
 https://github.com/TeamWertarbyte/material-ui-search-bar/issues/8
*/
.main-navigation .left button {
  height: 40px !important;
}

.main-navigation .left button > span {
  margin-top: -3px !important;
}

.country-flags > :hover {
  cursor: pointer;
}

.main-navigation .right a.active {
  text-decoration: underline;
}