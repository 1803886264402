.rc-tree-select-tree-title:hover {
  cursor: pointer;
}

.rc-tree-select-tree-node-selected {
  background-color: #deeaff;
  border: 1px #deeaff solid;
}

.rc-tree-select:not(.rc-tree-select-disabled) .rc-tree-select-selection-selected-value {
  color: black;
}

.rc-tree-select-tree li span.rc-tree-select-tree-switcher {
  background-image: none !important;
}

.rc-tree-select-tree li {
  margin: 5px 0px;
}

.rc-tree-select-tree .rc-tree-select-tree-node-content-wrapper {
  margin-left: 3px;
}

.rc-tree-select .rc-tree-select-selection {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: inherit;
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  overflow: hidden;
}

.rc-tree-select .rc-tree-select-arrow {
  margin-top: 3px;
}